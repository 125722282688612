<template>
  <div>
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col xl="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">ตั้งค่า Plugin</h1>
        </b-col>
      </CRow>

      <div class="bg-white pt-3 px-3">
        <b-row>
          <b-col
            class="mb-3"
            md="4"
            v-for="(item, index) in list"
            v-bind:key="index"
          >
            <b-card no-header no-body class="m-0">
              <b-card-body>
                <div class="setting-icon">
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="20"
                        fill="currentColor"
                        class="bi bi-three-dots-vertical"
                        viewBox="0 0 15 20"
                      >
                        <path
                          d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                        />
                      </svg>
                    </template>
                    <b-dropdown-item href="#" @click="onEdit(item)"
                      >Setting</b-dropdown-item
                    >
                  </b-dropdown>
                </div>
                <b-img :src="item.image" class="image-logo" />
                <div class="font-bold mt-2">{{ item.name }}</div>
                <p class="two-lines my-1">{{ item.description }}</p>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
    <ModalSettingPlugin
      ref="ModalSettingPlugin"
      @handleUpdateForm="handleUpdateForm"
    />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
  </div>
</template>

<script>
import ModalSettingPlugin from "./components/ModalSettingPlugin.vue";
import iconGoogle from "./../../../assets/images/icons/google.png";
import iconLine from "./../../../assets/images/icons/line.png";
import iconFacebook from "./../../../assets/images/icons/facebook.png";
import iconMessenger from "./../../../assets/images/icons/messenger.png";
import iconFile from "./../../../assets/images/icons/icon-file.png";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlert from "@/components/modal/alert/ModalAlert";

export default {
  name: "SettingPlugins",
  components: {
    ModalSettingPlugin,
    ModalAlertError,
    ModalAlert,
  },
  data: function () {
    return {
      modalMessage: "",
      bodyDelete: {
        platformId: 0,
        channelId: 0,
      },
      list: [],
      iconGoogle,
      iconLine,
      iconFacebook,
      iconMessenger,
      iconFile,
    };
  },

  created: async function () {
    await this.getList();
  },
  methods: {
    getList: async function () {
      this.$isLoading = false;
      this.list = [];
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Setting/GetValueList`,
        null,
        this.$headers
      );
      if (resData.result == 1) {
        let details = resData.detail;
        this.$store.commit("setSetting", details);
        this.list.push({
          name: "Facebook",
          type: "facebook",
          image: this.iconFacebook,
          apiKeyFacebook: details.filter((item) => item.id == 67)[0].value,
          apiVersionFacebook: details.filter((item) => item.id == 68)[0].value,
        });
        // this.list.push({
        //   name: "Facebook Pixel",
        //   type: "facebookPixel",
        //   image: this.iconGoogle,
        //   facebookPixelApiKey: details.filter((item) => item.id == 66)[0].value,,
        // });
        this.list.push({
          name: "Line",
          type: "line",
          image: this.iconLine,
          lineClientId: details.filter((item) => item.id == 65)[0].value,
          lineClientSecrect: details.filter((item) => item.id == 64)[0].value,
        });
        this.list.push({
          name: "Google",
          type: "google",
          image: this.iconGoogle,
          apiKeyGoogleClientId: details.filter((item) => item.id == 69)[0]
            .value,
          apiKeyGooglePluginName: details.filter((item) => item.id == 70)[0]
            .value,
        });
        this.list.push({
          name: "Widget Chat",
          type: "widgetChatUrl",
          image: this.iconMessenger,
          widgetChatUrl: details.filter((item) => item.id == 72)[0].value,
        });

        let signature = details.filter((item) => item.id == 95);
        if (signature.length) {
          this.list.push({
            name: "Signature",
            type: "isSignature",
            image: this.iconFile,
            isSignature: signature[0].value == "1" ? true : false,
          });
        }
      }
      this.$isLoading = true;
    },
    async handleUpdateForm(form) {
      if (form.type == "facebook") {
        let countResult = 0;
        countResult += await this.update({
          id: 67,
          name: "ApiKeyFacebook",
          value: form.apiKeyFacebook,
          isImage: 0,
        });
        countResult += await this.update({
          id: 68,
          name: "ApiVersionFacebook",
          value: form.apiVersionFacebook,
          isImage: 0,
        });

        if (countResult == 2) await this.updateSuccess();
      } else if (form.type == "facebookPixel") {
        let countResult = 0;
        countResult += await this.update({
          id: 66,
          name: "FacebookPixelApiKey",
          value: form.facebookPixelApiKey,
          isImage: 0,
        });

        if (countResult == 1) await this.updateSuccess();
      } else if (form.type == "line") {
        let countResult = 0;
        countResult += await this.update({
          id: 64,
          name: "LineClientSecrect",
          value: form.lineClientSecrect,
          isImage: 0,
        });
        countResult += await this.update({
          id: 65,
          name: "LineClientId",
          value: form.lineClientId,
          isImage: 0,
        });

        if (countResult == 2) await this.updateSuccess();
      } else if (form.type == "google") {
        let countResult = 0;
        countResult += await this.update({
          id: 69,
          name: "apiKeyGoogleClientId",
          value: form.apiKeyGoogleClientId,
          isImage: 0,
        });
        countResult += await this.update({
          id: 70,
          name: "apiKeyGooglePluginName",
          value: form.apiKeyGooglePluginName,
          isImage: 0,
        });

        if (countResult == 2) await this.updateSuccess();
      } else if (form.type == "widgetChatUrl") {
        let countResult = 0;
        countResult += await this.update({
          id: 69,
          name: "WidgetChatUrl",
          value: form.widgetChatUrl,
          isImage: 0,
        });

        if (countResult == 1) await this.updateSuccess();
      } else if (form.type == "isSignature") {
        let countResult = 0;
        countResult += await this.update({
          id: 95,
          name: "IsSignature",
          value: form.isSignature,
          isImage: 0,
        });

        if (countResult == 1) await this.updateSuccess();
      }
    },
    async updateSuccess() {
      this.modalMessage = "สำเร็จ";
      this.$refs.modalAlert.show();
      setTimeout(() => {
        this.$refs.modalAlert.hide();
      }, 2000);
      await this.$refs.ModalSettingPlugin.hide();
      await this.getList();
    },
    update: async function (form) {
      let result = 0;
      this.$isLoading = false;
      let resData = await this.$callApi(
        "Post",
        `${this.$baseUrl}/api/Setting/SetValue`,
        null,
        this.$headers,
        form
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        result = 1;
      } else {
        this.$refs.modalAlertError.show();
      }
      this.$isLoading = true;
      return result;
    },
    onEdit(item) {
      this.$refs.ModalSettingPlugin.show(item);
    },
  },
};
</script>

<style scoped>
.card-body {
  position: relative;
}
.setting-icon {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
}
::v-deep .setting-icon .dropdown button {
  padding: 4px;
  color: #3c4b64;
}
::v-deep .setting-icon .dropdown-menu-right {
  min-width: unset;
  width: unset;
  padding: unset;
}
.image-logo {
  border-radius: 0.5rem;
  /* width: 75px; */
  height: 40px;
  width: auto;
  /* aspect-ratio: 1; */
  /* border-radius: 50%; */
}
.two-lines {
  font-size: 13px;
  color: #6b6b6b;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>
