<template>
  <b-modal v-model="showModal" centered hide-header hide-footer>
    <div class="modal-header">
      <h3 class="font-weight-bold">Setting {{ form.name }}</h3>
      <button type="button" aria-label="Close" class="close" @click="hide">
        ×
      </button>
    </div>
    <div class="mt-3">
      <div v-if="form.type == 'facebook'">
        <InputText
          v-model="form.apiKeyFacebook"
          textFloat="Api Key"
          placeholder="Api Key"
          isRequired
          :isValidate="$v.form.apiKeyFacebook.$error"
          :v="$v.form.apiKeyFacebook"
          type="text"
        />
        <InputText
          v-model="form.apiVersionFacebook"
          textFloat="Version"
          placeholder="Version"
          type="text"
          class="mt-2"
          isRequired
          :isValidate="$v.form.apiVersionFacebook.$error"
          :v="$v.form.apiVersionFacebook"
        />
      </div>
      <div v-else-if="form.type == 'line'">
        <InputText
          v-model="form.lineClientId"
          textFloat="Client ID"
          placeholder="Client ID"
          isRequired
          :isValidate="$v.form.lineClientId.$error"
          :v="$v.form.lineClientId"
          type="text"
        />
        <InputText
          v-model="form.lineClientSecrect"
          textFloat="Client Secrect"
          placeholder="Client Secrect"
          type="text"
          class="mt-2"
          isRequired
          :isValidate="$v.form.lineClientSecrect.$error"
          :v="$v.form.lineClientSecrect"
        />
      </div>
      <div v-else-if="form.type == 'google'">
        <InputText
          v-model="form.apiKeyGoogleClientId"
          textFloat="Client ID"
          placeholder="Client ID"
          isRequired
          :isValidate="$v.form.apiKeyGoogleClientId.$error"
          :v="$v.form.apiKeyGoogleClientId"
          type="text"
        />
        <InputText
          v-model="form.apiKeyGooglePluginName"
          textFloat="Plugin Name"
          placeholder="Plugin Name"
          type="text"
          class="mt-2"
          isRequired
          :isValidate="$v.form.apiKeyGooglePluginName.$error"
          :v="$v.form.apiKeyGooglePluginName"
        />
      </div>
      <div v-else-if="form.type == 'widgetChatUrl'">
        <InputText
          v-model="form.widgetChatUrl"
          textFloat="Url"
          placeholder="Url"
          isRequired
          :isValidate="$v.form.widgetChatUrl.$error"
          :v="$v.form.widgetChatUrl"
          type="text"
        />
      </div>
      <div v-else-if="form.type == 'isSignature'">
        <b-row class="mt-4">
          <b-col>
            <label class="font-weight-bold main-label">
              สถานะการใช้งาน
            </label>
            <div>
              <b-form-checkbox
                switch
                v-model="form.isSignature"
                class="radio-active"
                size="lg"
              >
                <span class="ml-2 main-label">{{
                  form.isSignature ? "ใช้งาน" : "ไม่ใช้งาน"
                }}</span>
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="text-center mt-3">
      <b-button
        variant="main"
        class="float-right"
        :disabled="isLoading"
        @click="onSubmit"
        >บันทึก</b-button
      >
      <b-button
        variant="cancel"
        class="float-left mw-150px"
        @click="hide"
        :disabled="isLoading"
        >ปิด</b-button
      >
    </div>
  </b-modal>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { url, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "ModalSettingPlugin",
  components: {
    InputText,
  },
  validations: {
    form: {
      apiKeyFacebook: {
        required: requiredIf(function () {
          return this.form.type == "facebook";
        }),
      },
      apiVersionFacebook: {
        required: requiredIf(function () {
          return this.form.type == "facebook";
        }),
      },
      facebookPixelApiKey: {
        required: requiredIf(function () {
          return this.form.type == "facebookPixel";
        }),
      },
      lineClientId: {
        required: requiredIf(function () {
          return this.form.type == "line";
        }),
      },
      lineClientSecrect: {
        required: requiredIf(function () {
          return this.form.type == "line";
        }),
      },
      apiKeyGoogleClientId: {
        required: requiredIf(function () {
          return this.form.type == "google";
        }),
      },
      apiKeyGooglePluginName: {
        required: requiredIf(function () {
          return this.form.type == "google";
        }),
      },
      widgetChatUrl: {
        required: requiredIf(function () {
          return this.form.type == "widgetChatUrl";
        }),
        url,
      },
      isSignature: {},
    },
  },
  data: function () {
    return {
      showModal: false,
      isLoading: false,
      form: {
        name: "",
        type: "",
        image: "",
        apiKeyFacebook: "",
        apiVersionFacebook: "",
        //   facebookPixelApiKey: "",
        lineClientId: "",
        lineClientSecrect: "",
        apiKeyGoogleClientId: "",
        apiKeyGooglePluginName: "",
        widgetChatUrl: "",
        isSignature: "",
      },
    };
  },
  methods: {
    show: async function (item = false) {
      this.$v.$reset();
      if (item) this.form = { ...item };
      else this.setDefaultData();

      this.showModal = true;
    },
    setDefaultData() {
      this.form = {
        name: "",
        type: "",
        image: "",
        apiKeyFacebook: "",
        apiVersionFacebook: "",
        //   facebookPixelApiKey: "",
        lineClientId: "",
        lineClientSecrect: "",
        apiKeyGoogleClientId: "",
        apiKeyGooglePluginName: "",
        widgetChatUrl: "",
        isSignature: "",
      };
    },
    hide() {
      this.showModal = false;
    },
    onSubmit: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isLoading = true;
      if (this.form.type == "isSignature") {
        if (this.form.isSignature === true) this.form.isSignature = `1`;
        else this.form.isSignature = `0`;
      }

      this.$emit("handleUpdateForm", this.form);
      this.isLoading = false;
      this.hide();
    },
  },
};
</script>
<style scoped>
.modal-header {
  /* border-bottom: 0 !important; */
  margin: 0px;
  padding: 0px;
  padding-bottom: 0.25rem;
}
.text-msg {
  font-size: 2rem;
  color: #4a4a4a;
}
.text-text {
  color: #4a4a4a;
  margin-bottom: 0;
  font-size: 1.2rem;
}
.text-sub {
  color: #afce38;
  font-size: 1.25rem;
}
.modal-body {
  margin: auto;
  text-align: center;
  padding: 0;
}
.btn-modal {
  width: 100px;
  font-weight: bold;
}
</style>
